'use client'

import { usePathname } from '@lib/navigation'
import { Icon, Link } from '@shc/ui'
import { useLocale } from 'next-intl'

export default function IntlToggle() {
  const currentLocale = useLocale()
  const pathname = usePathname()

  const isEnglish = currentLocale === 'en'

  const text = isEnglish ? 'Español' : 'English'
  const newPathname = isEnglish ? '/es' + pathname : '/en' + pathname

  return (
    <Link href={newPathname} className="inline-flex flex-row items-center text-sm" noUnderline>
      <Icon icon="globe-americas" className="text-gray-700 pr-1.5" />
      <span>{text}</span>
    </Link>
  )
}
